// Dark layout overrides

.dark-layout {
  .popover {
    &.bs-popover-top {
      .arrow:after {
        border-top-color: $theme-dark-card-bg;
      }
      .arrow:before {
        border-top-color: $theme-dark-border-color;
      }
    }
    &.bs-popover-right {
      .arrow:after {
        border-right-color: $theme-dark-card-bg;
      }
      .arrow:before {
        border-right-color: $theme-dark-border-color;
      }
    }
    &.bs-popover-bottom {
      .arrow:after {
        border-bottom-color: $primary;
      }
      .arrow:before {
        border-bottom-color: $primary;
      }
    }
    &.bs-popover-left {
      .arrow:after {
        border-left-color: $theme-dark-card-bg;
      }
      .arrow:before {
        border-left-color: $theme-dark-border-color;
      }
    }
  }

  .ng-select,
  .ng-select div,
  .ng-select input,
  .ng-select span {
    color: $theme-dark-body-color;
  }
}

/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@font-face {
  font-family: "Segoe UI Historic";
  src: url("http://db.onlinewebfonts.com/t/0c5e6f133b0b25edfed47aca4ab57676.eot"); /* IE9*/
  src: url("http://db.onlinewebfonts.com/t/0c5e6f133b0b25edfed47aca4ab57676.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("http://db.onlinewebfonts.com/t/0c5e6f133b0b25edfed47aca4ab57676.woff2")
      format("woff2"),
    /* chrome firefox */
      url("http://db.onlinewebfonts.com/t/0c5e6f133b0b25edfed47aca4ab57676.woff")
      format("woff"),
    /* chrome firefox */
      url("http://db.onlinewebfonts.com/t/0c5e6f133b0b25edfed47aca4ab57676.ttf")
      format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url("http://db.onlinewebfonts.com/t/0c5e6f133b0b25edfed47aca4ab57676.svg#Segoe UI Historic")
      format("svg"); /* iOS 4.1- */
}
body {
  letter-spacing: 0.03rem;
  color: #52657c !important;
}

.direction-ltr {
  direction: ltr;
}
.direction-rtl {
  direction: rtl;
}

@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xs {
    width: 100%;
    padding-right: 7.3rem !important;
    padding-left: 7.3rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

/* Extra extra large devices (extra large desktops, 1400px and up) */
@media (min-width: 1400px) {
}

horizontal-menu {
  .main-menu-content {
    #main-menu-navigation {
      .dropdown.dropdown-submenu {
        ul.dropdown-menu {
          li:hover {
            color: #152858;
            border-radius: 7px;
            margin: 0 8px;
            background: rgba(228, 233, 240, 0.7);
            a {
              color: #152858;
            }
          }
        }
      }
      .dropdown.dropdown-submenu {
        ul.dropdown-menu {
          li {
            margin: 1px 8px;
          }
        }
      }
    }
  }
}

#navbarchatDropdown {
  .badge.badge-up {
    right: -2px;
  }
}
#navbarhistoryDropdown {
  .badge.badge-up {
    right: -2px;
  }
}

#actionBar {
  border-bottom: solid #d6d9e4 1px !important;
  padding: 22px 0;
}
@media (min-width: 576px) {
  #actionBar {
  }
  #actionBar {
    .actionBar-content {
      div {
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  #actionBar {
  }
  #actionBar {
    .actionBar-content {
      div {
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #actionBar {
  }
  #actionBar {
    .actionBar-content {
      div {
      }
    }
  }
}
@media (min-width: 1200px) {
  #actionBar {
  }
  #actionBar {
    .actionBar-content {
      div {
      }
    }
  }
}
#SubactionBar {
  height: 42px;
  border-bottom: solid #d6d9e4 1px !important;
  padding: 5px 0;
}

.content-wrapper {
  padding-top: 2rem;
}
//
.col-xxl,
.col-xxl-auto,
.col-xxl-12,
.col-xxl-11,
.col-xxl-10,
.col-xxl-9,
.col-xxl-8,
.col-xxl-7,
.col-xxl-6,
.col-xxl-5,
.col-xxl-4,
.col-xxl-3,
.col-xxl-2,
.col-xxl-1,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-bottom: 2rem;
}
.btn {
  margin: 2px;
}

a.d-flex.align-items-center.dropdown-item.dropdown-toggle.ng-star-inserted {
  color: #fff;
}

li.dropdown.dropdown-submenu.ng-star-inserted:hover {
  a.d-flex.align-items-center.dropdown-item.dropdown-toggle.ng-star-inserted {
    background: #fff !important;
    color: #1877f2;
  }
  a::after {
    background-image: url(str-replace(
      str-replace($chevron-down, "currentColor", #1877f2),
      "#",
      "%23"
    ));
  }
}
.badge-primary {
  background: #1877f2;
}

.widget-card {
  .card-body {
    .widget-body.widget-primary {
      hr {
        border-width: 4px;
        border-radius: 5px;
        border-color: rgba(#1877f2, 0.5);
        margin-bottom: 5px;
      }
    }
    .widget-body.widget-warning {
      hr {
        border-width: 4px;
        border-radius: 5px;
        border-color: rgba(#f2c218, 0.5);
        margin-bottom: 5px;
      }
    }
    .widget-body.widget-succes {
      hr {
        border-width: 4px;
        border-radius: 5px;
        border-color: rgba(#00b929, 0.5);
        margin-bottom: 5px;
      }
    }
    .widget-body.widget-light {
      hr {
        border-width: 4px;
        border-radius: 5px;
        border-color: rgba(#aeaeae, 0.5);
        margin-bottom: 5px;
      }
    }
    .widget-body.widget-danger {
      hr {
        border-width: 4px;
        border-radius: 5px;
        border-color: rgba(#f61a07, 0.5);
        margin-bottom: 5px;
      }
    }
    .widget-body.widget-secondary {
      hr {
        border-width: 4px;
        border-radius: 5px;
        border-color: rgba(#152858, 0.5);
        margin-bottom: 5px;
      }
    }
    .widget-body {
      height: 100%;
      h4 {
        font-weight: 300;
        font-size: 0.875rem;
      }
      h3 {
        font-weight: 600;
        span.text-danger {
          color: #ff3a29;
        }
        span.text-succes {
          color: #34b53a;
        }
        span {
          font-size: 0.875rem;
        }
      }
    }
  }
}
.btn-page-action {
  padding: 7px !important;
}
.btn-page-action::after {
  display: none;
}

@media (max-width: 1201px) {
  .horizontal-layout.navbar-static .app-content {
    padding: 4.5rem 0rem 0 !important;
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1877f2 !important;
  text-decoration: none;
  background-color: rgba(228, 233, 240, 0.7);
}
.btn.btn-link:hover {
  font-weight: bold !important;
}
h6,
.h6 {
  font-size: 0.875rem;
}
.bold {
  font-weight: bold;
}
.datatable-scroll {
  width: auto !important;
}

.filters-section {
  background: rgba(240, 243, 255, 0.5);
  padding-top: 1rem;
  box-shadow: 0 5px 15px 0px rgb(34 41 47 / 10%);
  border-radius: 7px;
  display: flex;
  flex-wrap: wrap;
}

.ng2-flatpickr-input.form-control.input {
  background: #fff !important;
}
.datatable-body {
  overflow-y: hidden;
}
.datatable-row-center:hover {
  .datatable-body-cell {
    background-color: rgba(240, 243, 255);
  }
  .bg-table-footer.datatable-body-cell {
    background-color: rgba(240, 243, 255, 0.2);
  }
}

.datatable-body-cell-label .datatable-icon-right {
  font: normal normal normal 14px/1 FontAwesome !important;
  font-size: inherit !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: rgb(236 240 244);
  padding: 4px 5px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 3px;
  color: #52657c !important;
  border-radius: 3px;
}
.datatable-body-cell-label .datatable-icon-right:hover {
  color: #52657c !important;
}
.datatable-body-cell-label .datatable-icon-right::before {
  content: "\f067" !important;
  font-family: "FontAwesome" !important;
}

.datatable-body-cell-label .datatable-icon-down {
  font: normal normal normal 14px/1 FontAwesome !important;
  font-size: inherit !important;
  text-rendering: auto !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: rgb(236 240 244);
  padding: 4px 5px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 3px;
  color: #52657c !important;
  border-radius: 3px;
}
.datatable-body-cell-label .datatable-icon-down:hover {
  color: #52657c !important;
}
.datatable-body-cell-label .datatable-icon-down::before {
  content: "\f068" !important;
  font-family: "FontAwesome" !important;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f0f3ff;
  -webkit-border-radius: 10px;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background: #52657c;
}

.widget-body h4 {
  text-transform: uppercase;
}
.dashboard-Filter-title {
  color: rgba(82, 101, 124, 0.7) !important;
}

.page-title h3 {
  font-weight: 600;
}

.text-primary.invoice-logo {
  color: rgb(82 101 124 / 70%) !important;
  font-weight: 400;
  font-size: 15px !important;
}
.modal-title {
  color: rgba(82, 101, 124) !important;
  font-size: 14px !important;
}
.nav-tabs {
  border-bottom: solid 1px #d6d9e4;
  margin: 0 -20px;
  padding: 0 20px;
}
.nav-tabs .nav-link {
  border: solid 1px #d6d9e4;
  border-right: 0px;
  border-bottom: 0px;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  font-size: 14px;
  font-weight: bold;
  background: rgba(236, 240, 244, 0.5);
}
.nav-tabs .nav-item:last-child .nav-link {
  border-right: solid 1px #d6d9e4;
}
.nav-tabs .nav-link.active:after {
  top: 0 !important;
  bottom: auto !important;
}
.nav-tabs .nav-link.active {
  border-bottom: solid 3px #ffff;
  margin-bottom: -2px;
}
.nav-tabs .nav-link {
  color: #52657c !important;
}

.text-Paying-Client {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $info !important;
  border-color: $info !important;
  background-color: $info;
}
#invoiceTotal {
  min-width: 300px;
}

.bg-table-footer {
  background: rgba(240, 243, 255);
}
.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  position: absolute;
  left: 7px;
}
.sortable {
  .datatable-header-cell-template-wrap {
    .datatable-header-cell-wrapper {
      padding-left: 12px;
    }
  }
}

.ngx-datatable.bootstrap.custom-bootstrap .datatable-header {
  background-color: rgb(236 240 244) !important;
  text-transform: uppercase;
}

.nav-tabs .nav-link:hover {
  border-color: #d6d9e4;
  border-bottom: none;
  color: #1877f2 !important;
  background-color: rgba(240, 243, 255, 0.5);
}
.nav-tabs .nav-link.active:hover {
  border-bottom: solid 3px #ffff;
}
.dropdown-menu .dropdown-item {
  box-shadow: none;
}
.table-hover tbody tr:hover {
  color: #52657c;
  background-color: rgba(240, 243, 255, 0.5);
}
.table th,
.table td {
  padding: 0.72rem 1rem;
}

.Stop-span {
  position: absolute;
  right: 0px;
}
.timeline-stop-time {
  margin-right: 5rem;
}
.Time-line-title {
  max-width: 85% !important;
}

.popover-header {
  background-color: #f0f3ff;
  border-bottom: 0 solid #f0f3ff;
  color: #52657c !important;
}
.popover .popover-header {
  font-size: 13px;
  border: 1px solid #f0f3ff;
}
.datatable-body-cell-label .btn {
  font-size: 13px !important;
}
.btn-sm {
  line-height: 14px;
}
.moveIcon {
  position: absolute;
  right: 7px;
  top: 4px;
  color: #52657c;
}
.bg-table-footer {
  .datatable-body-cell-label {
    font-weight: bold;
    .btn.btn-link {
      font-weight: bold;
      color: #152858 !important;
    }
  }
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: rgba(236, 240, 244, 0.5);
  color: #52657c;
  font-size: 10.4px;
}
.ngx-datatable.bootstrap.core-bootstrap
  .datatable-header
  .datatable-header-cell {
  padding-right: 0px;
}

.nav-pills .nav-link {
  border-radius: 0.358rem;
  color: #152858;
  font-size: 14px;
}
.nav-pills .nav-link:hover {
  border-radius: 0.358rem;
  color: #1877f2 !important;
  font-size: 14px;
}

.nav-pills .nav-link.active:hover {
  color: #fff !important;
}
img.rounded {
  border-radius: 50% !important;
}

.avatar.profileImage [class*="avatar-status-"] {
  border-radius: 50%;
  width: 13px;
  height: 13px;
  position: absolute;
  right: 0;
  bottom: 7px;
  border: 1px solid #fff;
}
.w-30 {
  width: 30%;
}
.font-light {
  font-weight: 100;
}
label {
  font-size: 13px;
}
.badge {
  font-weight: normal !important;
  font-size: 13px;
}
.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
}
.datatable-header-cell {
  font-size: 13px !important;
}
.popover-body p {
  margin-bottom: 0px;
}
.user-status {
  font-size: 13px;
  font-weight: normal;
}
.notification-text {
  font-size: 13px;
  font-weight: normal;
}
.btn-sm {
  font-size: 13px !important;
  font-weight: normal !important;
}
.invoice-number {
  font-weight: normal;
}
.inv-custom-hr {
  margin-right: -1.6rem;
  margin-left: -1.6rem;
}

.sub-table tbody tr td {
  font-size: 13px;
}
.table {
  color: #152858;
}
.table.sub-table {
  color: #52657c;
}

.MoveEnabled {
  border: solid 1px #1877f2;
  border-radius: 7px;
  padding: 5px;
}
.WidgetmoveIcon {
  position: absolute;
  right: 13px;
  top: 4px;
  color: #52657c;
}

.ngx-datatable .datatable-body .datatable-scroll {
  color: #152858;
}
.breadcrumb-item a:hover {
  font-weight: bold !important;
}
.info-container {
  margin-bottom: 10px;
}
.info-title {
  color: #1b3065;
  font-weight: 600;
}
.info-value {
  color: #1b3065;
  font-weight: 400;
}
.w-40 {
  width: 40%;
}
.w-70 {
  width: 70%;
}
.popover {
  width: 100% !important;
}

@media (min-width: 1200px) {
  .modal-xl {
    margin-left: 10%;
    margin-right: 10%;
  }
  .modal-xl {
    max-width: 1010px;
    margin: auto;
  }
}
.card-body.invoice-padding {
  padding: 0.5rem 1.5rem !important;
}
.hint {
  color: rgb(82 101 124 / 70%) !important;
  font-size: 10px;
  text-transform: uppercase;
}
.text-hint {
  color: rgb(82 101 124 / 70%) !important;
  font-size: 10px;
  text-transform: uppercase;
}
.Uppercase {
  text-transform: uppercase;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.ngx-datatable.bootstrap.core-bootstrap
  .datatable-header
  .datatable-header-cell-template-wrap {
  font-weight: 500;
}

.badge-success {
  background-color: rgba(0, 185, 41, 0.5);
}
.ng-select-focused {
  border-bottom: solid 1px #1877f2;
}
.nav-pills .nav-link.active {
  box-shadow: none !important;
}
.border-start {
  border-left: solid 1px #ebe9f1 !important;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .page-count {
  color: #b9b9c3;
  text-align: center;
  padding-left: 200px;
  position: relative;
  top: 15px;
}
.btn-Load-More {
  position: relative !important;
  bottom: 39px;
}
.title-font {
  font-size: 15px;
}

#Loader {
  width: 99vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background: rgba(82, 101, 124, 0.2);
  z-index: 999;
}

input[type="password"]::-webkit-input-placeholder {
  font-size: 20px;
}
input[type="password"] {
  font-size: 20px;
}

.ng-select-opened .ng-select-container .ng-arrow-wrapper span {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAASCAYAAABFGc6jAAAACXBIWXMAAA9kAAAPZAGFhcKfAAABE0lEQVQ4jaXVvZHCMBAF4Ae5hgtRRgfnEtzB0QG4AshQCcqgg4MOrgTowJRwkdIDFWBmYT3j859W4kUOpPdZGsmeVFWFOtr4EkDprFrjjWjjtwCoI3dW/VHTtIEcAXwCWPFzUnjunrvO2vgP6nmuSBt/ALBpFV+bbyQJI6vW0IuzKp/Md3da4vdAjxgbQOqcaOsOI/P/LT8RoWQE5QBuqZgA+SVj6qwqUzEBQp1L2vrnqYvEFhFIzt1o36OMygDMAgU05kuKdKAIbCwdBM0LW4cHvPNlWLeRXggv7AdAkYAUPLeTXoixYyRW8JzeDEKR2CiCECTEgogICmAnCSKGBjBCxKezc49C0cZTeeasop+bLAAeJCySL9LhFOcAAAAASUVORK5CYII=);
  background-size: 8px 5px, 9px 7px;
  background-repeat: no-repeat;
  height: 0.8rem !important;
  padding-right: 1.5rem;
  margin-left: 0;
  margin-top: 10px;
  left: 0;
  border-style: none !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: #152858 !important;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: rgb(82 101 124 / 50%) !important;
}
.form-control {
  color: rgb(82 101 124 / 0.7) !important;
}
.card .card-title.text-primary {
  color: #152858 !important;
  font-size: 15px !important;
}
.Load-More-section {
  height: 0px;
  padding: 0px !important;
}
.popover .popover-body {
  padding-top: 22px;
  padding-bottom: 22px;
}
.horizontal-menu footer {
  padding: 22px;
}
.thead-default tr th,
.sub-table tr th {
  border-bottom: none !important;
}
.w-80px {
  width: 80px;
}
.dashboard-title {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}
.btn-Load-More {
  margin-right: 40px;
}
.datatable-body {
  border-bottom: 1px solid #ebe9f1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: rgba(236, 240, 244, 0.5) !important;
  opacity: 1;
}
.control-sm ng2-flatpickr .ng2-flatpickr-input-container .ng2-flatpickr-input {
  height: 2.142rem;
  padding: 0.188rem 0.857rem;
}

.info-container {
  display: table-row;
}
.info-title,
.info-value {
  display: table-cell;
}
.info-value {
  display: table-header-group;
}
.info-title {
  padding-right: 20px;
  border-bottom: 10px solid transparent;
}
.col-form-label {
  font-weight: 500;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.form-control-adr {
  color: rgba(82, 101, 124, 0.7) !important;
  background: #f1f1f2;
  border: none;
  font-size: 13px !important;
}

.form-control-adr:focus {
  background-color: #f1f1f2;
  border: solid 1px #1877f2;
}
.input-group-adr .input-group-append .input-group-text {
  background-color: #f4f6f8;
  border-color: #f4f6f8;
}
select.form-control-adr {
  padding: 0 15px !important;
  height: 32px;
}

select.form-control-adr {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #f4f6f8 !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAASCAYAAABFGc6jAAAACXBIWXMAAA9kAAAPZAGFhcKfAAABE0lEQVQ4jaXVvZHCMBAF4Ae5hgtRRgfnEtzB0QG4AshQCcqgg4MOrgTowJRwkdIDFWBmYT3j859W4kUOpPdZGsmeVFWFOtr4EkDprFrjjWjjtwCoI3dW/VHTtIEcAXwCWPFzUnjunrvO2vgP6nmuSBt/ALBpFV+bbyQJI6vW0IuzKp/Md3da4vdAjxgbQOqcaOsOI/P/LT8RoWQE5QBuqZgA+SVj6qwqUzEBQp1L2vrnqYvEFhFIzt1o36OMygDMAgU05kuKdKAIbCwdBM0LW4cHvPNlWLeRXggv7AdAkYAUPLeTXoixYyRW8JzeDEKR2CiCECTEgogICmAnCSKGBjBCxKezc49C0cZTeeasop+bLAAeJCySL9LhFOcAAAAASUVORK5CYII=) !important;
  background-repeat: no-repeat !important;
  background-position-x: 94% !important;
  background-position-y: 12px !important;
  background-size: 12px 7px, 12px 6px !important;
}
.form-table-control {
  padding-bottom: 5px;
}
.form-table-title {
  padding-right: 20px;
  white-space: nowrap;
  padding-bottom: 5px;
}

@media (min-width: 576px) {
  .form-row .form-container:nth-child(odd) {
    padding-right: 13px !important ;
  }
  .form-row .form-container:nth-child(even) {
    padding-left: 13px !important ;
  }
}

@media (min-width: 768px) {
  .form-row .form-container:nth-child(odd) {
    padding-right: 13px !important ;
  }
  .form-row .form-container:nth-child(even) {
    padding-left: 13px !important ;
  }
}

@media (min-width: 992px) {
  .form-row .form-container:nth-child(odd) {
    padding-right: 25px !important ;
  }
  .form-row .form-container:nth-child(even) {
    padding-left: 25px !important ;
  }
}

.form-control-adr.ng-select .ng-select-container {
  background: #f1f1f2 !important;
  border-radius: none;
}

.form-control-adr.ng-select .ng-select-container {
  color: #52657c !important;
  min-height: 28px !important;
  border: none !important;
}

.form-control-adr.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  position: initial;
}
.form-control-adr.ng-select.ng-select-opened > .ng-select-container {
  border: none !important;
}
.ngx-datatable.bootstrap.core-bootstrap
  .datatable-body-row
  .datatable-body-cell {
  min-height: 58px;
}
select.form-control:not([multiple="multiple"]) {
  padding-right: 2.5rem;
}
#page-account-settings .row .tab-content {
  min-height: 100%;
}

#page-account-settings .row .tab-content .tab-pane {
  height: 100%;
}
#page-account-settings .row .tab-content .card {
  height: 100%;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* to hide pagination on tables */
.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  display: none;
}
.noClick {
  pointer-events: none;
}
.cui-avatar-border-white {
  border-color: #fff;
}

.cui-avatar-90 {
  width: 6.92rem;
  height: 6.92rem;
}

.cui-avatar {
  width: 5.69rem;
  height: 5.69rem;
  background-color: #eef0f4;
  border-color: #b8beca;
  display: inline-block;
  overflow: hidden;
  border-radius: 500px;
}

.bg-pink {
  background-color: #f1f1f2 !important;
}

.app-profile-avatar {
  font-size: 25px;
  background-color: #f1f1f2 !important;
  color: #152858 !important;
  border-radius: 50%;
  width: 75px;
  height: 37px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  background-position: center center;
  background-size: cover;
  display: flex;
}

.cui-avatar-nav-bar {
  width: 2.69rem;
  height: 2.69rem;
  background-color: #eef0f4;
  border-color: #b8beca;
  display: inline-block;
  overflow: hidden;
  border-radius: 500px;
}
.app-profile-avatar-nav-bar {
  font-size: 13px;
  background-color: #f1f1f2 !important;
  color: #152858 !important;
  border-radius: 50%;
  width: 36px;
  height: 0px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  background-position: center center;
  background-size: cover;
  display: flex;
}

input.password {
  -webkit-text-security: disc;
  text-security: disc;
}
